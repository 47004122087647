@import "../vendor/bootstrap/less/bootstrap";
@import "../vendor/font-awesome/less/font-awesome";
@import "fonts";
@import "variables";
/*
* Vendor specific
*/
/* alison test */
@import "vendors/parsley";
@import "vendors/slick";

html, body {
  height: 100%;
}

#slideshow47 .slick-slide {
    @media (max-width: 1194px) {
        margin-top: 108px !important;
    }
    @media (max-width: 972px) {
        margin-top: 159px !important;
    }
    @media (max-width: 768px) {
        margin-top: 44px !important;
        .content-heading{ margin-top: 0px;}
    }
    
    
    
}
.mb-2-custom {
    //margin-bottom: 29px;
}
.panel-relatedpanel .slideshow{
    margin-top: 150px;
}
.products-new{
    .slick-dots {
        position: relative;
        bottom: 0;
        li {

            border: 1px solid #2f2d2d;
            
        }

    }
}

#container {
  min-height: 90%;
}

#sidebar {
  padding-top: 1 * @font-size-h1;
}

.mt-1 {
  margin-top: @line-height-computed * 1px;
}

.mt-2 {
  margin-top: @line-height-computed * 2px;
}

.mt-3 {
  margin-top: @line-height-computed * 3px;
}

.mb-1 {
  margin-bottom: @line-height-computed * 1;
}

.mb-2 {
  margin-bottom: @line-height-computed * 2;
}

.mb-3 {
  margin-bottom: @line-height-computed * 3;
}

.align-right {
  float: right;
  padding-right:0px;
}

.align-left {
  float: left;
  padding-left:0px;
}

.align-center {
  text-align:center;
}

.align-right, .align-left {
  @media (max-width: @screen-xs) {
    padding-left: 0;
    padding-right: 0;
    float: none;
}
}

.clear-both {
  clear: both;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}

.vertical-align-middle {
  position: relative;
  top: 50%;
  .translate(0%, -50%);
}

.horizontal-align-center {
  .translate(-50%, -50%);
  left: 50%;
  position: absolute;
  z-index: 99;
}

.container, .background-grey {
  position: relative;
}

@import url(http://fonts.googleapis.com/css?family=Lato:100,300,400,700,900,100italic,300italic,400italic,700italic,900italic);
body,
html {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: 'Lato', sans-serif;
    color: #6f6f6f;
}
::-webkit-scrollbar { width: 2px;  }/* for vertical scrollbars */
::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.1); }
::-webkit-scrollbar-thumb { background: rgba(0, 0, 0, 0.5); }

@media (min-width: 1200px) { 
    .container { width: 1100px }
}
h1 {
    font-weight: 900;
    text-transform: uppercase;
}
h1 small { text-transform: lowercase }
h2 {
    line-height: 24px;
    font-weight: 300;
}
h5 { font-size: 16px }
p { font-size: 16px }
a {
    color: @brand-primary;
    transition: all 0.5s;
    font-weight:bold;
}
a:hover,
a:focus {
    text-decoration: none;
    color: darken(@brand-primary,20%);
}
.highlight { color: @brand-primary }
.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.clearfix { display: inline-block }
* html .clearfix { height: 1% }
.clearfix { display: block }
/* Navigation */
#tf-menu {
    margin-bottom: 0;
    background-color: @white;
    padding: 18px 0;
    height: auto;
    box-shadow: 0 0 6px 2px rgba(0,0,0,.31);
}
.navbar-brand img {
    height: 65px;
    width: auto;
}
.navbar-brand { padding: 0 15px }

.navbar-nav > li > a, .navbar-default .navbar-nav>li>a {
    font-size: 13px;
    font-weight: 700;
    border-bottom: 2px solid transparent;
    padding: 5px 5px;
    margin: 8px 10px;
    text-transform: uppercase;
    transition: all 0.5s;
    color:@gray-light;
}
.navbar-default .navbar-nav > li > a.active,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus,.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
    color: @gray-dark;
    border-bottom: 4px solid @brand-primary;
}
.navbar-default .navbar-toggle { border-color: transparent }
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus { background-color: transparent }
.navbar-default .navbar-toggle:hover>.navbar-default .navbar-toggle .icon-bar,
.navbar-default .navbar-toggle:focus>.navbar-default .navbar-toggle .icon-bar { color: @brand-primary }
/* Header Page */
#tf-header {
    padding: 20px 0;
    background: @brand-primary;
    margin-top: 70px;
    color: @white;
}
#tf-header .breadcrumb {
    padding: 8px 15px;
    margin-bottom: 20px;
    list-style: none;
    background-color: transparent;
    border-radius: 4px;
    margin-left: -10px;
    margin-top: -10px;
}
#tf-header .breadcrumb > li + li:before {
    padding: 0 5px;
    color: #FFF;
    content: "/\00a0";
}
#tf-header .breadcrumb > li a { color: @white }
/* Home Style */
#tf-home {
    background: url(../img/bg/01.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    color: #cfcfcf;
}


#tf-home h1 { color: @brand-primary }
//.content-heading p.lead { margin-bottom: 80px }
a.goto-btn {
    font-size: 18px;
    font-weight: 700;
    border-bottom: 2px solid transparent;
    padding: 10px 0;
    transition: all 0.5s;
}
a.goto-btn:hover {
    color: @white;
    border-bottom: 2px solid @brand-primary;
}
/* Home Section Style 2 - With Video*/
#tf-home.video .overlay {
    padding: 0;
    padding-top: 10%;
}
.img-wrapper { position: relative }
.img-wrapper img.img-responsive { margin: 0 auto !important }
.img-wrapper i.fa {
    position: absolute;
    z-index: 10;
    top: 50%;
    font-size: 25px;
    background: rgba(32, 22, 22, 0.29);
    padding: 18px 23px;
    height: 60px;
    width: 60px;
    text-align: center;
    border-radius: 50%;
    color: @white;
    margin-left: -30px;
    transition: all 0.3s;
}
.img-wrapper i.fa:hover { background: @brand-primary }
/* Home Section Style 3 - Slider*/
#tf-home.slider { background: none }
#tf-home.slider .overlay { padding: 0 }
.carousel-caption {
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    left: 50%;
    right: auto;
    bottom: auto;
    z-index: 10;
    padding: 0;
    color: @white;
    text-align: center;
    text-shadow: none;
    //width: 100%;
    @media (max-width: 700px) {
        width: 80%;
    }
    h1 {
      color:@brand-secondary;
      text-transform: none;
      margin-top:0;
  }
  p {
      color:@white;
      margin-bottom: 0px;
  }
  .largeCaption {
    color: #fc3;
    font-size: 36px;
    font-weight: normal;
    ul {
        margin:0;
        padding: 0;
        li {
            list-style: none;
        }
    }
}
}
.carousel-indicators li, .slick-dots li {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid @white;
    border-radius: 10px;
}
.slideshow .slick-slide {
  .slide {
    min-height:100%;
    position: absolute;
    left:0;
    background-size:cover;
    background-position:center;
    width:100%;
}
}
.carousel-indicators {
    position: absolute;
    bottom: 60px;
    left: 50%;
    z-index: 15;
    width: 60%;
    padding-left: 0;
    margin-left: -30%;
    text-align: center;
    list-style: none;
}
.carousel-indicators .active {
    width: 14px;
    height: 14px;
    margin: 0;
    background-color: @brand-primary;
}
.slick-dots li button {
    &:before {
      color: transparent;
      
      border-radius: 100%;
      height: 10px;
      width: 10px;
  }
}
.slick-dots li.slick-active button:before {
  background:@brand-secondary;
  color:transparent;
}
.carousel-fade .carousel-inner .item {
    opacity: 0;
    transition-property: opacity;
}
.carousel-fade .carousel-inner .active { opacity: 1 }
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
    left: 0;
    opacity: 0;
    z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right { opacity: 1 }
.carousel-fade .carousel-control { z-index: 2 }
/* Home Section Style 4 - App Header Style*/
#tf-home.app .overlay { padding: 10% 0 5% 0 }
#tf-home.app {background: url(../img/bg/02.jpg);}
a.goto-btn { margin-right: 30px }
.ipad-wrapper .app-wrap {
    position: relative;
    width: 395px;
}
.app-wrap {
    position: absolute;
    background: url(../img/header4.png);
    width: 395px;
    height: 540px;
    background-repeat: no-repeat;
    z-index: 2;
    left: 5%;
}

.app .content-heading.text-left { padding: 15% 0 }
#tf-home.app .owl-theme .owl-controls .owl-page span {
    width: 15px;
    height: 15px;
    margin: 5px;
    background: @white !important;
    border: 2px solid transparent;
    opacity: 1;
}
#tf-home.app .owl-theme .owl-controls {
    margin-top: 60px;
    text-align: center;
}
#tf-home.app .owl-theme .owl-controls .owl-page.active span,
#tf-home.app .owl-theme .owl-controls.clickable .owl-page:hover span { border: 2px solid @brand-primary }

/* Home Parallax Effect */
.bcg {
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  width: 100%;
}

#tf-home.parallax .bcg { background-image:url('../img/bg/slider03.jpg'); }
.parallax  .content-heading{
    padding: 20% 0;
    text-align: center;
}
/* Intro Section */
#tf-intro {
    background-color: darken(@brand-primary, 10%);
    padding: 5% 0;
    color: @white;
    text-align: center;
}
#tf-intro img.intro-logo {
    margin: 0 auto;
    margin-bottom: 30px;
}
/* Service Section */
#tf-services { padding: 60px 0 }
.section-header {
    padding: 40px 0;
    text-align: center;
}
.section-header h2 { text-transform: uppercase }
.section-header h5 { letter-spacing: 1px }
.fancy {
    line-height: 0.5;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 20px;
}
.fancy span {
    display: inline-block;
    position: relative;
}
.fancy span:before,
.fancy span:after {
    content: "";
    position: absolute;
    height: 5px;
    border-bottom: 1px solid #f1f1f1;
    top: 15px;
    width: 400px;
}
.fancy span:before {
    right: 100%;
    margin-right: 10px;
}
.fancy span:after {
    left: 100%;
    margin-left: 10px;
}
/* service lists */
.service { margin-bottom: 20px }
.service .media-right i.fa {
    text-align: center;
    font-size: 30px;
    background-color: @brand-primary;
    color: @white;
    padding: 25px 0;
    border-radius: 50px;
    border-top-left-radius: 0;
    margin: 0 10px;
    margin-right: 20px;
    width: 82px;
    height: 80px;
    transition: background-color 0.5s;
}
.service .media-left i.fa {
    text-align: center;
    font-size: 30px;
    background-color: @brand-primary;
    color: @white;
    padding: 25px 0;
    border-radius: 50px;
    border-top-right-radius: 0;
    margin: 0 10px;
    margin-left: 20px;
    width: 82px;
    height: 80px;
    transition: background-color 0.5s;
}
.process:hover i.fa,
.service:hover i.fa { background-color: @brand-secondary }
h4.media-heading {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    color: #333;
    line-height: 20px;
}
/* About Us */
#tf-about { padding: 40px 0 }
.gray-bg { background-color: #f3f3f3 }
.img-wrap .profile-img {
    position: relative;
    margin-bottom: 40px;
}
.profile-img .social {
    position: absolute;
    z-index: 1;
    bottom: -10px;
    left: 50px;
}
.about-right-content { padding: 40px 0 }
.about-left-content { padding-bottom: 60px }
.about-left-content h2,
.about-left-content h2 small { color: #333 }
.about-left-content h2 small { font-weight: 300 }
ul.social li a.fa {
    background: @brand-primary;
    width: 50px;
    height: 50px;
    padding: 15px;
    text-align: center;
    margin-right: -5px;
    font-size: 20px;
    color: @white;
    margin-bottom: 0;
}
ul.social li a.fa:hover { background-color: @brand-secondary }
/* Skills Progress Bar */
.progress {
    height: 12px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #999999;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: @white;
    text-align: center;
    background-color: #FFCC32;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}
.skills {
    margin-top: 30px;
    padding: 20px 0;
}
.skillset p {
    font-size: 13px;
    margin-bottom: 5px;
}
/* Counter */
#tf-counter {
    border-top: 1px solid #D1D1D1;
    border-bottom: 1px solid #D1D1D1;
}
.facts { padding: 0 }
.facts .count-box { position: relative }
.count-box {
    -webkit-backface-visibility: hidden;
    border-left: 1px solid #d3d3d3;
    padding: 40px 0;
    margin: 0;
    overflow: hidden;
    transition: all 0.5s;
    transform: translateX(0px) translateY(0%);
    -webkit-transform: translateX(0px) translateY(0%);
    -moz-transform: translateX(0px) translateY(0%);
    -ms-transform: translateX(0px) translateY(0%);
    -o-transform: translateX(0px) translateY(0%);
}
/*.count-box .hover-bg{ 
  position: absolute;
  display: block;
  background-color: #FFCC32;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.5s;
  transform: translateX(0px) translateY(150px);
  -webkit-transform: translateX(0px) translateY(150px);
  -ms-transform: translateX(0px) translateY(150px);
    -o-transform: translateX(0px) translateY(150px);
    opacity: 0;
}
.count-box:hover .hover-bg{
  background: #FFCC32;
  color: @white !important;
  transform: translateX(0px) translateY(-40px);
    -webkit-transform: translateX(0px) translateY(-40px);
    -moz-transform: translateX(0px) translateY(-40px);
    -ms-transform: translateX(0px) translateY(-40px);
    -o-transform: translateX(0px) translateY(-40px);
    opacity: 1;
    }*/
    .count-box:hover,
    .count-box:hover > i.fa { color: @white }
    .count-box:hover {
        -webkit-backface-visibility: hidden;
        background-color: darken(@brand-primary, 10%);
    }
    .counter i.fa {
        font-size: 40px;
        color: @brand-primary;
    }
    .count-box.last { border-right: 1px solid #d3d3d3 }
    /* Portfolio / Works Sections */
    #tf-team { padding: 60px 0 }
    #team .item {
        padding: 30px 0px;
        margin: 5px 20px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }
    .item .hover-bg {
        position: relative;
        overflow: hidden;
        .content {
          position:relative;
      }
  }
  .hover-bg .hover-text {
    position: absolute;
    display: block;
    bottom: 3px;
    width: 100%;
    min-height: 25%;
    padding: 20px;
    color: @white;
    transition: all 0.8s;
    -webkit-transition: all 08s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;

}
.hover-bg .hover-text.on { opacity: 1 }
.hover-bg .hover-text.off {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transform: translateX(100%) translateY(3px);
    transform: translateX(100%) translateY(3px);
    opacity: 1;
    background: rgba(0, 0, 0, 0.70);
}
.hover-bg:hover .hover-text.off {
    -webkit-transform: translateX(0%) translateY(3px);
    transform: translateX(0%)  translateY(3px);
    opacity: 1;
}
.owl-theme .owl-controls .owl-page span {
    width: 15px;
    height: 15px;
    margin: 5px;
    background: #bababa;
}
/* Why Us or Features Section */
#tf-features,
#feature { padding: 60px 0 }
.features-content { padding: 0 30px 0; height:100%;
    .tab-pane {
        height:100%;
    } 
}
.features-content h4 {
    text-transform: uppercase;
    margin-bottom: 20px;
    color: @gray-dark;
}
.nav.nav-pills > li > a {
    position: relative;
    display: block;
    background-color: @white;
    padding: 25px;
    border-bottom: 3px solid #cccccc;
    text-transform: uppercase;
    margin-bottom: 5px;
}
.nav.nav-pills > li > a small {
    text-transform: lowercase;
    font-style: italic;
}
.nav.nav-pills > li > a:hover,
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    color: @white;
    background-color: @brand-primary;
    padding: 25px;
    border-bottom: 3px solid darken(@brand-primary, 20%);
}
.nav-pills > li > a { border-radius: 4px }
ul.features li span.fa {
    font-size: 35px;
    vertical-align: middle;
    margin-right: 20px;
    float: left;
    clear: both;
    margin-top: 5px;
}
/* Portfolio or works blocks */
#tf-works { padding: 60px 0 }
ul.cat { margin: 30px 0 }
ul.cat li a {
    background: inherit;
    border: 1px solid;
    padding: 5px 10px;
    border-radius: 3px;
}
ul.cat li a:hover,
ul.cat li a:focus,
ul.cat li a.active {
    background: #f1f1f1;
    border: 1px solid;
    padding: 5px 10px;
    border-radius: 3px;
    color: inherit;
}
.nopadding { padding: 0 }
.box a { margin: 2px }
.box .hover-bg {
    position: relative;
    overflow: hidden;
}
.box .hover-bg .hover-text {
    height: 100%;
    bottom: 0;
    -webkit-transform: translateX(0%) translateY(0%);
    transform: translateX(0%) translateY(0%);
    background: transparent;
}
.box:hover .hover-bg .hover-text { background-color: rgba(1, 1, 1, 0.5) }
.box .hover-bg:hover .hover-text.off {
    -webkit-transform: translateX(0%) translateY(0%);
    transform: translateX(0%)  translateY(0%);
    opacity: 1;
}
.box .hover-text i.fa {
    font-size: 20px;
    background: darken(@brand-primary, 10%);
    padding: 13px;
    position: relative;
    top: 40%;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    transition: all 0.5s;
}
.box .hover-text i.fa:hover { background: @gray-dark }
.box .hover-text i.fa.fa-expand {
    border-top-right-radius: 0;
    -webkit-transform: translateX(-400%) translateY(0%);
    transform: translateX(-400%)  translateY(0%);
    opacity: 0;
    transition: all 0.5s;
}
.box:hover .hover-text i.fa.fa-expand {
    -webkit-transform: translateX(0%) translateY(0%);
    transform: translateX(0%)  translateY(0%);
    opacity: 1;
}
.box .hover-text i.fa.fa-chain {
    border-top-left-radius: 0;
    -webkit-transform: translateX(400%) translateY(0%);
    transform: translateX(400%)  translateY(0%);
    opacity: 0;
    transition: all 0.5s;
}
.box:hover .hover-text i.fa.fa-chain {
    -webkit-transform: translateX(0%) translateY(0%);
    transform: translateX(0%)  translateY(0%);
    opacity: 1;
}
/* Style 2 - Not Fullwidth */
#itemsWorkThree .box,
#itemsWorkTwo .box { margin-bottom: 30px }
#itemsWorkTwo .hover-bg .hover-text { padding: 30% 0 }
#itemsWorkTwo .box:hover .hover-bg .hover-text {
    background-color: rgba(255, 223, 125, 0.70);
    border: 10px solid rgba(185, 156, 72, 0.80);
}
#itemsWorkTwo .box .hover-bg .hover-text h5 small {
    text-transform: capitalize;
    color: #000000;
}
#itemsWorkTwo .box .hover-bg .hover-text h5 {
    font-weight: 700;
    text-transform: uppercase;
    color: #000000;
    -webkit-transform: translateX(0%) translateY(400%);
    transform: translateX(0%)  translateY(400%);
    opacity: 0;
    transition: all 0.5s;
}
#itemsWorkTwo .box:hover .hover-bg .hover-text.off h5 {
    -webkit-transform: translateX(0%) translateY(0%);
    transform: translateX(0%)  translateY(0%);
    opacity: 1;
}
#itemsWorkTwo .box .hover-text i.fa {
    font-size: 20px;
    background: transparent;
    padding: 13px;
    position: relative;
    top: 40%;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    transition: all 0.5s;
    border: 1px solid @white;
}
#itemsWorkTwo .box .hover-text i.fa:hover { background: @gray-dark }
/* Portfolio Style #3 */
#itemsWorkThree .box h5 {
    border: 1px solid #ddd;
    padding: 10px 0;
    margin: 0;
}
/* Isotope Filter */
.isotope-item { z-index: 2 }
.isotope-hidden.isotope-item { z-index: 1 }
.isotope,
.isotope .isotope-item {
    /* change duration value to whatever you like */
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    transition-duration: 0.8s;
}
.isotope-item {
    margin-right: -1px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.isotope {
    -webkit-transition-property: height, width;
    -moz-transition-property: height, width;
    transition-property: height, width;
}
.isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    transition-property: transform, opacity;
}
.nivo-lightbox-theme-default .nivo-lightbox-title {
    font-size: 14px;
    background: #f1f1f1;
    border: 1px solid #333;
    letter-spacing: 2px;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 10px 35px;
    border-radius: 2px;
}
.nivo-lightbox-theme-default.nivo-lightbox-overlay {
    background: @white !important;
    background: rgba(255, 255, 255, 1) !important;
}
.nivo-lightbox-theme-default .nivo-lightbox-nav,
.nivo-lightbox-theme-default .nivo-lightbox-close { opacity: 1 }
.nivo-lightbox-error {
    color: #C59A6D !important;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0) !important;
}
.nivo-lightbox-theme-default .nivo-lightbox-image img {
    background: @white;
    -webkit-box-shadow: 0px 1px 1px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 1px 1px rgba(0,0,0,0.4);
    box-shadow: 0px 1px 1px rgba(0,0,0,0.4);
    border-top: 4px solid @brand-primary;
}
/* Single Portfolio Detail */
#work-detail-one,
#work-detail-two,
#work-detail-three { padding: 60px 0 }
.work-detail .dl-horizontal dt {
    text-align: left;
    margin-bottom: 10px;
}
.work-related-thumb { margin-bottom: 30px }
.work-related-thumb .img-thumbnail {
    display: inline-block;
    max-width: 100%;
    height: auto;
    padding: 0;
    line-height: 1.42857143;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
.work-related-thumb .img-thumbnail:hover { opacity: 0.8 }
/* Process Section */
#tf-process { padding: 60px 0 }
.process i.fa {
    text-align: center;
    font-size: 30px;
    background-color: @brand-primary;
    color: @white;
    padding: 25px 0;
    border: 4px solid @white;
    border-radius: 50px;
    border-top-left-radius: 0;
    margin: 0 10px;
    margin-right: 20px;
    width: 92px;
    height: 90px;
    transition: background-color 0.5s;
}
#tf-process .gray-bg { position: relative }
.gray-bg, .background-grey {
  .vline {
      position: absolute;
      height: 100%;
      padding: 5px;
      border-left: 3px solid @white;
      left: 20%;
      top:0px;
  }
}
#process { padding: 60px 0 20px 0 }
.process { margin-bottom: 40px }
/* Pricing Section */
#tf-pricing { padding: 60px 0 }
.price h1 { font-size: 50px }
.price { text-align: center }
.price.panel-default { border-color: #333 }
.price.panel-default > .panel-heading {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: #FFF;
    background-color: @gray-dark;
    border-color: inherit;
}
.price .panel-heading { padding: 30px 15px }
.price .panel-body { border-bottom: 1px solid }
.price ul.list-unstyled { padding: 20px; }
.price ul.list-unstyled li { padding: 10px 0 }
.price ul.list-unstyled li small { margin-top: -10px }
/* Featured */
.price.featured {
    -moz-box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.31);
    -webkit-box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.31);
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.31);
}
.price.featured.panel-default {
    border-color: @brand-primary;
    background-color: @white;
    position: relative;
    z-index: 1;
    margin-left: 10px;
    margin-right: 10px;
    .current {
      margin-left:-5px;
      margin-right:-5px;
      margin-top: -20px;
  }
}
.price.featured.panel-default { border-color: @brand-primary }
.price.featured.panel-default > .panel-heading { background-color: @brand-primary }
.featured .panel-body { margin-top: 20px }
.featured .panel-body,
.featured.panel-default > .panel-heading { border-color: @brand-primary }
.featured .tf-btn { margin-top: 20px }
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
    outline: 0;
    outline: 0;
    outline-offset: 0;
}
.btn:active,
.btn.active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.tf-btn {
    font-size: 12px;
    word-spacing: 2px;
    font-weight: 700;
    padding: 10px 20px;
    background-color: @gray-dark;
    text-transform: uppercase;
    border-color: @gray-dark;
    transition: all 0.5s;
}
.tf-btn:focus,
.tf-btn:hover {
    background-color: @gray-dark;
    border-color: @brand-primary;
}
.color {
    background-color: @brand-primary;
    border-color: @brand-primary;
}
.alt-color {
  background-color: @brand-secondary;
  border-color: @brand-secondary;
  color: @text-color;
  &:hover, &:focus {
    border-color: @gray-dark;
    background-color: @brand-secondary;
}
}
.color:focus,
.color:hover {
    border-color: @gray-dark;
    background-color: @brand-primary;
}
/* Blog Section */
#tf-blog,
#blog-post { padding: 60px 0 }
#blog-post a.btn { margin: auto 0 }
.post-wrap { position: relative }
.media.post {
    background: @white;
    padding: 10px;
}
.post-wrap {
    position: relative;
    margin-bottom: 30px;
    transition: all 0.5s;
}
.post-meta {
    border-top: 1px solid #ebebeb;
    background-color: @white;
    padding: 5px 10px;
    height: auto;
    position: relative;
    min-height: 35px;
}
ul.metas li:after {
    content: ' / ';
    margin-right: 3px;
    margin-left: 3px;
}
ul.metas li:last-child:after { content: ' ' }
a.secondary {
  color:inherit;
}
a.slide {
  color:inherit;
  &:hover {
    color:inherit;
}
}
ul.metas li a, a.secondary:hover { color: darken(@brand-secondary, 20%) }
ul.metas li a.green {
  color:@green;
}
ul.metas li a:hover {
  color:@brand-primary;
}
.meta-detail {
    background: #f4f4f4;
    border-radius: 3px;
    padding: 2px;
}
/* Contact Section */
/*********************************
/* Map Section
**********************************/
#tf-contact { padding: 60px 0 }
#map {
    position: relative;
    width: 100%;
    height: 400px;
}
.contact-detail {
    text-align: center;
    margin: 60px 0;
}
.contact-detail i.fa {
    text-align: center;
    font-size: 30px;
    background-color: @brand-primary;
    color: @white;
    padding: 25px 0;
    border-radius: 50px;
    border-top-left-radius: 0;
    margin: 0 10px;
    margin-right: 20px;
    width: 82px;
    height: 80px;
    transition: background-color 0.5s;
}
.contact-detail:hover i.fa { background-color: @gray-dark }
form#contact-form { padding: 40px 0 }
.contact form#contact-form {padding: 0}
.form-control:focus {
    border-color: inherit;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.form-control {
    display: block;
    width: 100%;
    height: 44px;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: @white;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}
p.help-block.text-danger ul li {
    display: block;
    margin-left: -20px;
    color: @brand-primary;
}
#success .alert-danger {
    color: @brand-primary;
    background-color: #FDFDFD;
    border-color: @brand-primary;
    margin-bottom: 10px;
}
/* Footer */
.license_copy {
    font-size: 12px;
    color: #e8f5ff;
    font-style: italic;
}
#tf-footer {
    padding: 3% 0 2% 0;
    background-color: darken(@brand-primary, 10%);
    color: @white;
}
ul.social li a i {
    font-size: 18px;
    background: transparent;
    width: 32px;
    height: 32px;
    padding: 7px 0;
    text-align: center;
    border-radius: 50%;
    border-top-left-radius: 0;
    border: 1px solid transparent;
    color: @white;
    transition: all 0.5s;
}
ul.social li a i:hover { border-color: @white }
/* Blog Page */
.blog .media.post {
    background: @white;
    padding: 10px;
    border: 1px solid #E9E9E9;
    border-bottom-color: transparent;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.blog .post-meta {
    border-top: 1px solid #ebebeb;
    background-color: @white;
    padding: 5px 10px;
    height: auto;
    position: relative;
    min-height: 35px;
    border: 1px solid #ebebeb;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.pagination > li > a,
.pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: 0;
    line-height: 1.42857143;
    color: #FFFFFF;
    text-decoration: none;
    background-color: @brand-primary;
    border: 1px solid @brand-primary;
    margin: 3px 3px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 5px;
}
.pagination > li > a.active,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    color: #FFFFFF;
    background-color: #ddab15;
    border-color: #ddab15;
}
.pagination {
  width:100%;
  text-align: center;
  a {
    padding:50px;
}
}
/* Sidebar */
.widget {
    margin-bottom: 40px;
    padding: 0 20px;
}
.comment h4,
.widget h4,
h4.text-uppercase {
    font-weight: 300;
    color: @brand-primary;
}
.comment h4 span.comments { color: @gray-dark }
/* Search */
button.btn.btn-default {
    padding: 11px 15px;
    color: @white;
    background: @brand-primary;
}
/* Categories and Archive Widget */
ul.bullet-lists { margin-top: 20px }
ul.list-unstyled.bullet-lists li span.fa.fa-circle {
    font-size: 8px;
    vertical-align: middle;
    margin-right: 10px;
    color: @brand-primary;
}
ul.list-unstyled.bullet-lists li { padding: 5px 0 }
ul.list-unstyled.bullet-lists li:hover { color: @brand-primary }
/* Post Tab */
.post-tab .nav-tabs > li > a {
    margin-right: -1px;
    line-height: 1.42857143;
    border: 1px solid #ddd;
    border-radius: 0px 0px 0 0;
}
.post-tab .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -1px;
}
.post-tab .nav-tabs > li > a:hover,
.post-tab .nav-tabs > li > a:focus {
    color: #FFF;
    background-color: #FC3;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
}
/* Tags */
ul.list-inline.bullet-lists li a { color: inherit }
ul.list-inline.bullet-lists li {
    background: #F8F8F8;
    padding: 5px 10px;
    margin-bottom: 5px;
    margin-right: 2px;
    border: 1px solid #F4F4F4;
    border-radius: 3px;
    transition: all 0.3s;
}
ul.list-inline.bullet-lists li:hover { background: @brand-primary }
/* Single Blog Post */
.blog-post .post-wrap img,
.work-detail img.featured { margin: 20px 0 }
ul.meta-tags li a { font-style: italic }
ul.meta-tags li:after { content: ',' }
ul.meta-tags li:last-child:after { content: ' ' }
/* Author Box */
.media.post-author {
    background: @brand-primary;
    padding: 20px;
    margin-top: -1px;
}
.media.post-author a { content: inherit }
.blog-post .post-wrap {
    padding: 20px;
    margin-bottom: 0;
    border: 1px solid #DDDDDD;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
/* Comment */
.media.comment-block {
    border: 1px solid #DDD;
    padding: 20px;
}
.comment { margin-top: 40px }
.comment form { margin-top: -30px }
.comment input,
.comment textarea { margin-bottom: 15px }

/* iPads (portrait and landscape) ----------- */
@media (max-width: 768px) {
  body{ overflow-x: hidden;}
  .navbar-header {
    float: none;
}
.navbar-toggle {
    display: block;
}
.navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
}
.navbar-collapse.collapse {
    display: none !important;
}
.navbar-fixed-top .navbar-collapse, 
.navbar-fixed-bottom .navbar-collapse {
    max-height: 420px;
    text-align: center;
}
.gray-bg .vline {
  left: 11%;
}
.navbar-right {
  float: none !important;
  margin-right: -15px;
}
.navbar-nav {
    float: center !important;
    margin: 7.5px -15px;
}
.navbar-nav>li {
    float: none;
}
.navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
}
.navbar-text {
    float: none;
    margin: 15px 0;
}
.navbar-collapse.collapse.in {display: block!important;}
.collapsing {overflow: hidden!important; }
.navbar-nav {margin: 0 -15px;}
.navbar-nav .open .dropdown-menu > li > a, .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 10px 15px 10px 25px;
}
.price.panel-default {
  margin: 40px;
}
.price.featured.panel-default {
  margin: 40px;
}
.content-heading{ margin-top: 80px;}
.ipad-wrapper .app-wrap {left: 22%;}
#tf-home.app .content-heading.text-left{text-align: center;}
#itemsWorkTwo .box .hover-text i.fa {
  top: 0%;
}
}
@media (max-width: 768px) {
  #tf-home.slider .content-heading {
      margin-top: 30px;
  }
  #tf-home.slider h1 {
      color: #FC3;
      font-size: 28px;
  }
  .slider .content-heading p.lead { margin-bottom: 20px; }
  .slider .carousel-indicators { bottom: 30px; }
}
@media (max-width: 603px) { 
  .slider a.goto-btn { display: none;}
  .ipad-wrapper .app-wrap {left: 16%;}
  #tf-footer .pull-left,
  #tf-footer .pull-right {
      float: none !important;
      text-align: center;
  }
}

@media (max-width: 460px) {
  .gray-bg .vline {
      left: 15%;
  }
  .meta-detail { display: none; }
  h1 { font-size: 34px;}
  #tf-home.slider h1 { font-size: 26px;}
  //p.lead { display: none;}
  .slider .carousel-indicators { bottom: 0px; }
  .ipad-wrapper .app-wrap {left: 3%;}
}
@media (max-width: 360px) { 
  #tf-services,
  #tf-pricing,
  .section-header,
  .about-right-content,
  #tf-features, 
  #feature,
  #tf-works,
  #tf-blog,
  #tf-contact {
    padding: 20px 0;
}
p{ font-size: 16px}
h2{ font-size: 20px;}
h4{ line-height: 20px;}
ul.list-inline.cat > li{  margin-bottom: 20px;}
.gray-bg .vline { left: 20%;}
.post-meta {min-height: 50px;}
.price.panel-default {
  margin: 20px;
}
.price.featured.panel-default {
  margin: 20px;
}
h1 {font-size: 26px}
#tf-home.slider{ display: none;}
#tf-intro.slider{ margin-top: 70px !important}
.ipad-wrapper .app-wrap {display: none;}
a.goto-btn {
  margin-right: auto;
  margin: 0 10px;
}
a.goto-btn {
  display: inline-block;
}
.blog .post-meta {min-height: 55px;}
}
@media (max-width: 320px) { 
  .post-wrap .post .media-body p {display: none;}
  .gray-bg .vline { left: 25%;}
  .nav.nav-pills > li > a { font-size: 11px;}
  ul.features li span.fa {
      font-size: 30px;
      margin-top: 2px;
  }
  .post-tab .nav-tabs > li > a {
      padding: 5px 10px;
  }
}

//CSS over rides
.panel-relatedpanel.slideshow {
  position: relative;
  .overlay {
    background: -moz-linear-gradient(top,  rgba(83,83,83,0.8) 0%, rgba(83,83,83,0.73) 17%, rgba(83,83,83,0.66) 35%, rgba(83,83,83,0.55) 62%, rgba(83,83,83,0.4) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(83,83,83,0.8)), color-stop(17%,rgba(83,83,83,0.73)), color-stop(35%,rgba(83,83,83,0.66)), color-stop(62%,rgba(83,83,83,0.55)), color-stop(100%,rgba(83,83,83,0.4))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(83,83,83,0.8) 0%,rgba(83,83,83,0.73) 17%,rgba(83,83,83,0.66) 35%,rgba(83,83,83,0.55) 62%,rgba(83,83,83,0.4) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(83,83,83,0.8) 0%,rgba(83,83,83,0.73) 17%,rgba(83,83,83,0.66) 35%,rgba(83,83,83,0.55) 62%,rgba(83,83,83,0.4) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(83,83,83,0.8) 0%,rgba(83,83,83,0.73) 17%,rgba(83,83,83,0.66) 35%,rgba(83,83,83,0.55) 62%,rgba(83,83,83,0.4) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(83,83,83,0.8) 0%,rgba(83,83,83,0.73) 17%,rgba(83,83,83,0.66) 35%,rgba(83,83,83,0.55) 62%,rgba(83,83,83,0.4) 100%); /* W3C */
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 1;
}
}

.background {
  padding:70px 0px;
}

.background-blue {
  background-color:@brand-primary;
  color:@white;
  padding:30px 0px;
  .media-heading {
    color:@white;
}
}
.background-white {
  background-color:@white;
  color:@text-color;
  padding:30px 0px;
  .media-heading {
    color:@text-color;
}
}
.background-yellow {
  background-color: @brand-secondary;
  color:@white;
  padding:30px 0px;
  .media-heading {
    color:@white;
}
}
.background-grey, .matrix-blockquote {
  background-color:@gray-lighter;
  padding:30px 0px;
}

[data-slick-carousel-default] {
  margin-bottom:0px;
}

.panel-relatedpanel {
  margin-bottom:64px;
}
.panel-relatedpanel {
  &.slideshow, &.latestnews {
    margin-bottom:0px;
}
}

.media-object {
  width:200px;
}

.modal-dialog {
  @media (min-width:@screen-sm) {
    width: 80%;
}
.modal-header, .modal-footer {
    overflow: hidden;
    border-bottom:none;
}
.modal-body {
    background:@gray-lighter;
}
}

ul.button {
  position:absolute;
  bottom:0px;
  width:100%;
}

img.staff-profile {
  width:100%;
  margin-right:auto;
  margin-left:auto;
  
}


.article-sharing {
    float: left;
}

a.share-btn {
    margin-right: 10px;
    color:@brand-primary !important;
}

.matrix-downloads.downloads-list-group {
    width: 100%;
    clear: both;
}

blockquote {
  position:relative;
  padding:12px 54px;
  margin-bottom:0px;
  &:before {
    content: "\f10d";
    position: absolute;
    top: 0px;
    left: 20px;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 30px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color:darken(@brand-secondary,20%);
}
footer {
    color:darken(@brand-secondary,20%);
}
}

.blockquote-reverse, blockquote.pull-right {
  text-align:left;
  padding:12px 54px;
}

.disclaimer {
  margin-top:80px;
}

form#contact {
  margin-bottom: 80px;
  .heading {
    display: none;
}
.col-md-4 {
    padding:0px;
    @media (min-width:@screen-md) {
      padding:0px 0px 0px 15px;
  }
}
.col-md-4:first-of-type {
    padding:0px;
}
}
.page-header {
  padding-bottom:0px;
  margin: 48px 0px 0px 0px;
  border:none;
}

#search {
  input {
      outline: none;
  }
  input[type=search] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    font-family: inherit;
    font-size: 100%;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
    display: none; 
}


input[type=search] {
    background: @white url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
    border: solid 1px #ccc;
    padding: 9px 10px 9px 32px;
    width: 55px;
    
    -webkit-border-radius: 10em;
    -moz-border-radius: 10em;
    border-radius: 10em;
    
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}
input[type=search]:focus {
    width: 130px;
    background-color: #fff;
    border-color: #66CC75;
}


input:-moz-placeholder {
    color: #999;
}
input::-webkit-input-placeholder {
    color: #999;
}

/* Demo 2 */
#demo-2 input[type=search] {
    width: 15px;
    height:15px;
    margin-top:9px;
    padding-left: 10px;
    color: transparent;
    cursor: pointer;
    position:absolute;
}
#demo-2 input[type=search]:hover {
    background-color: @white;
}
#demo-2 input[type=search]:focus {
    width: 130px;
    padding-left: 32px;
    color: @text-color;
    background-color: @white;
    cursor: auto;
}
#demo-2 input:-moz-placeholder {
    color: transparent;
}
#demo-2 input::-webkit-input-placeholder {
    color: transparent;
}
}

.login {
  a {
    font-size: 25px !important;
    }
}

.yellowline {
  height:4px;
  width:50px;
  background-color: @brand-secondary;
  margin:10px auto;
  display:block;
}

h4 {
  .yellowline {
    width:100%;
}
}

ul.footernav {
    list-style: none;
    padding:0px;
    li {
        display:inline-block;
        padding:0px 15px;
        border-right:1px solid @white;
        &:last-of-type {
            border-right:none;
        }
        a {
            color:@white;
            font-weight:400;
            &:hover, &:focus {
                color:@brand-secondary;
            }
        }
    }
}
.call-to-action {
    margin-top:0;
}
.list-unstyled {
    &.features {
        padding:20px 20px 80px 20px !important;
    }
    &.button {

    }
}
.media.post{
    display: table;
}
.media-left {
    display: table-cell;
    vertical-align: top;
}
.btn-primary{
   margin-bottom: 15px;
   margin-right: 15px;
   
   &.blue{
    color: #fff;
    background-color: @brand-primary;
    border-color: @brand-primary;
    
    &:hover{
        background-color:darken(@brand-primary,10%);
        color: #fff;
    }
}
&.yellow{
    margin-top: 15px;
    color: #000;
    background-color: @brand-secondary;
    border-color: @brand-secondary;
    

    &:hover{
        background-color:darken(@brand-secondary,10%);
        color: #000;
    }
}
}
.btn-primary{
    margin-bottom: 20px;
}
.btn-sm{
    font-size: 12px;
}
.btn-md{
    font-size: 14px;
}
.btn-lg{
    font-size: 18px;

}
